<script>
//import API from "../api/API";
export default {
  name: "Mixin",
  data() {
    return {
      userSignInData: {
        user_account: "",
        user_pwd: "",
      },
      userInfo: null,
      FormContent:{
      },
      pageContent:{

      },
      TokenID: "",
      Token: "",
      //userRequest: this.$axios.create({ baseURL: "../api" }), // 本機端
      userRequest: this.$axios.create({ baseURL: "api" }), // domainname端
      apiToken: (data) => this.userRequest.post("API/Default/Token", data),
    };
  },
  created() { },
  mounted() { },
  computed: {},
  methods: {
    userSignCheck() {
      if (sessionStorage.getItem("TokenID") === null) {
        alert("請登入系統!!");
        this.$router.push({ path: "/Login" });
      }
    },
    date2str(date) {
      //西元轉民國
      return [
        date.getFullYear(),
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1),
        date.getDate() > 9 ? date.getDate() : "0" + date.getDate(),
      ];
    },
    str2dateStr(str){
      let year = parseInt(str.substring(0,3))+1911
      let month = str.substring(3,5)
      let day = str.substring(5,7)
      return  year.toString() + "-" + month + "-" + day;
    },
    IsDateValid(val){
      //console.log("IsDateValid",val)
      return !Number.isNaN(new Date(val).valueOf());
    },
    date2str2(date) {
      //西元轉民國
      let year = date.getFullYear()
      let month = date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)
      let day =date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
      return (year - 1911).toString() + month.toString() + day.toString()
    },
    covertDate(val){
      let date = new Date(val);
      let year = date.getFullYear()-1911;
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return year.toString()+month.toString().padStart(2, '0')+day.toString().padStart(2, '0');
    },
    FunctionToken: function (EXfunction, data_in) {
      this.apiToken({})
        .then((res) => {
          data_in.TokenID = this.TokenID;
          data_in.Token = res.data;
          EXfunction(data_in);
        })
        .catch((error) => {
          alert("發生錯誤!" + error);
        });
    },
    _sessionData(key, val = undefined) {
      if (val == undefined) {
        return sessionStorage.getItem(key);
      } else if (val == "") {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(key, val);
      }
    },
    IsNull(data) {
      if (data == "" || data == null || data == undefined) {
        return "";
      } else {
        return data;
      }
    },
    IsNullEmpty(data){
      if(data == "" || data == null || data == undefined){
        return true;
      }else{
        return false;
      }
    },
    _go(url) {
      if (isNaN(url)) {
        this.$router.push(url);
      } else {
        this.$router.go(url);
      }
    },
    _go2(json, type = "params") {
      /*
      {
          name:'test1',
          path:'/test1',
          data:{},
          key:'www',
      }
      */
      if (type == "params") {
        if (this.IsNull(json.key) != "") {
          return this.$route.params[json.key];
        } else {
          this.$router.push({
            name: json.name,
            params: json.data,
          });
        }
      } else if (type == "query") {
        if (this.IsNull(json.key) != "") {
          return this.$route.query[json.key];
        } else {
          this.$router.push({
            path: json.path,
            query: json.data,
          });
        }
      }
    },
  },
};
</script>
